import { KardexParams, ReportParams } from "src/features/Reports/state/types";

export const Urls = {
    BACKEND: {
        LOGIN: () => `/login`,
        COMPANY: {
            GET_BY_ID: (companyId: number) => `/company/${companyId}`,
        },
        CUSTOMER: {
            GET_RECORDS: () => '/customers'
        },
        CUSTOMER_PAYMENT: {
            GET_RECORDS: (dateFrom: string, dateTo: string) => `/customer/payments?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        },
        DTE: {
            GET_INVOICE_RECIEVER_BY_ID: (id: string) => `/invoice-receiver/${id}`,
            GET_DTE_PDF: (id: string) => `/pdf?uuid=${id}`,
        },
        SUPPLIER: {
            GET_RECORDS: () => '/suppliers'
        },
        PRODUCT: {
            GET_RECORDS: (queryParams: string = "") => `/products${queryParams}`,
            CREATE: '/product',
            GET_BY_ID: (id: number) => `/product/${id}`,
            UPDATE: '/product',
        },
        PRODUCT_BRAND: {
            GET_SETTINGS: (queryParams: string = "") => `/product-settings${queryParams}`,
        },
        PURCHASE: {
            CREATE: `/purchase`,
            GET_RECORDS: (queryStringParams: string) => `/purchase-records${queryStringParams}`,
            GET_BY_ID: (id: number) => `/purchase/${id}`,
        },
        PURCHASE_PAYMENT: {
            CREATE: `/purchase-payment`,
            GET_RECORDS: (queryStringParams: string) => `/purchase-payments${queryStringParams}`,
        },
        REPORTS: {
            GET_KARDEX_REPORT: (params: KardexParams) => `/report-sale-detail?type=kardexSale&productId=${params.productId}&dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`,
            GET_UNITS_SOLD_REPORT: (params: ReportParams) => `/report-sale-detail?type=unitsSold&dateFrom=${params.dateFrom}&dateTo=${params.dateTo}`,
        },
        ROUTING: {
            CREATE_SALE: `/routing-sale`,
            GET_CUSTOMERS: `/routing-customers`,
        },
        SALE: {
            CREATE: `/sale`,
            GET_RECORDS: (dateFrom: string, dateTo: string) => `/sale-records?dateFrom=${dateFrom}&dateTo=${dateTo}`,
            GET_BY_ID: (saleId: number) => `/sale/${saleId}`,
            CERTIFY_SALE: `/sale-recertify`,
        },
        WAREHOUSE_STOCK: {
            GET_RECORDS: (warehouseId: number) => `/stock/${warehouseId}`,
        },
    },
    FRONTEND: {
        HOME: '/',
        LOGIN: '/login',
        LOGOUT: '/logout',
        PRODUCT: {
            PRODUCTS: '/products',
            CREATE: '/product-create',
            EDIT: '/product-create/:id',
            EDIT_PAGE: (productId: number) => `/product-create/${productId}`,
            STOCK: '/warehouse-stock',
        },

        CUSTOMER: {
            CUSTOMER_RECORDS: '/customers',
            EDIT_CUSTOMER: (customerId: string = ':id'): string => `/customer/${customerId}`
        },
        CUSTOMER_PAYMENT: {
            RECORDS: '/customer-payments',
        },
        PURCHASE: {
            CREATE: '/purchase',
            RECORDS: '/purchases',
            PAYABLES: '/payables',
        },
        REPORTS: {
            KARDEX: '/report-kardex',
            UNITS_SOLD: '/report-units-sold',
        },
        ROUTING: {
            CREATE_SALE: '/routing-sale',
        },
        SALE: {
            CREATE: '/sale',
            RECORDS: '/sales',
            RECEIVABLES: '/receivables',
        },
        SUPPLIER: {
            SUPPLIER_RECORDS: '/suppliers'
        },
        USER: {
            USERS: '/users',
            EDIT_URL: (userId: number) => `/user/${userId}`,
        },
        WAREHOUSE_STOCK: {
            RECORDS: `/warehouse-stock`,
        },
    },
}