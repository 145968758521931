import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'

function* createSaleApi(action: Actions.CreateSaleAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.SALE.CREATE, action.sale)
        yield put(Actions.createSaleSuccess(response.data))
    } catch (err) {
        yield put(Actions.createSaleError(err));
    }
}

function* getSalesRecordsApi(action: Actions.GetSaleRecordsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.SALE.GET_RECORDS(action.dateFrom, action.dateTo))
        yield put(Actions.getSaleRecordsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getSaleRecordsError(err));
    }
}

function* getSalesByIdApi(action: Actions.GetSaleByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.SALE.GET_BY_ID(action.saleId))
        yield put(Actions.getSaleByIdSuccess(response.data))
    } catch (err) {
        yield put(Actions.getSaleByIdError(err));
    }
}

function* certifySaleApi(action: Actions.CertifySaleAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.SALE.CERTIFY_SALE, action.sale)
        yield put(Actions.certifySaleSuccess(response.data))
    } catch (err) {
        yield put(Actions.certifySaleError(err));
    }
}

export default function* saleSagas() {
    yield takeLatest(ActionTypes.CREATE_SALE_API, createSaleApi)
    yield takeLatest(ActionTypes.GET_SALE_RECORDS_API, getSalesRecordsApi)
    yield takeLatest(ActionTypes.GET_SALE_BY_ID_API, getSalesByIdApi)
    yield takeLatest(ActionTypes.CERTIFY_SALE_API, certifySaleApi)
}