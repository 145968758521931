import { ErrorAction, GetCompanyByIdSuccessAction } from "./action";
import { GET_COMPANY_API, GET_COMPANY_API_ERROR, GET_COMPANY_API_SUCCESS } from "./actionTypes";
import { Company } from "../models/company";
import { CommonError, CommonErrorOrigin } from "./types";

export interface CommonState {
    company?: Company
    error?: CommonError
}

const initialState: CommonState = {
}

const commonReducer = (state: CommonState = initialState, action: any): CommonState => {
    switch (action.type) {
        case GET_COMPANY_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case GET_COMPANY_API_SUCCESS: {
            const successAction = action as GetCompanyByIdSuccessAction;

            return {
                ...state,
                company: successAction.company,
            }
        }
        case GET_COMPANY_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: CommonErrorOrigin.GET_COMPANY_BY_ID,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default commonReducer;