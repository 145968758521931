import { useDispatch, useSelector } from "react-redux"
import { Sale } from "src/common/models/sale"
import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import moment from "moment"
import dayjs from 'dayjs'
import { useEffect, useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import { SystemConstants } from "src/common/constants"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_SALE_BY_ID_API, GET_SALE_RECORDS_API } from "../state/actionTypes"
import { TABLE_COLUMNS, summaryColumns } from "./config"
import { clearCurrentSale, getSaleByIdApi, getSaleRecordsApi } from "../state/action"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { Button, Dropdown, MenuProps } from "antd"
import { SaleDetailModal } from "./SaleDetailModal"
import { DownOutlined } from '@ant-design/icons'
import { SystemDescriptions } from "src/common/descriptions"
import { Loader } from "src/components/Loader"
import { ReCertificationDTE } from "./ReCertificationDTE"
import { formatToDisplayDate, getDteDocument } from "src/common/util"
import { XlsExporter } from "src/common/XlsExporter"

interface ReduxProps {
    isLoadingData: boolean
    saleRecords?: Sale[]
    isGettingSaleById: boolean
    currentSale?: Sale
}

export const SaleRecords = () => {

    const dispatch = useDispatch()

    const [selectedAction, setSelectedAction] = useState<"DETAIL" | "RE_CERTIFY" | undefined>(undefined)

    useEffect(() => {
        dispatch(clearCurrentSale())
        dispatch(getSaleRecordsApi(
            dateRanges.startDate,
            dateRanges.endDate
        ))
    }, [])

    const descriptions = SystemDescriptions.PAGES.SALE.RECORDS

    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Sale) => {
                const handleDetailClick = (saleId: number) => {
                    setSelectedAction("DETAIL")
                    dispatch(getSaleByIdApi(saleId))
                }

                const handleReCertifyClick = (saleId: number) => {
                    setSelectedAction("RE_CERTIFY")
                    dispatch(getSaleByIdApi(saleId))
                }

                const handleGetDteClick = (authId: string | null) => {
                    if (authId) {
                        getDteDocument(authId)
                    }
                }

                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleDetailClick(record.id!)}
                            >
                                {descriptions.ACTIONS.VIEW_DETAIL}
                            </Button>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleGetDteClick(record.authorization)}
                                disabled={!record.isDteCertified}
                            >
                                {descriptions.ACTIONS.GET_DTE}
                            </Button>
                        ),
                    },
                    {
                        key: '3',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleReCertifyClick(record.id!)}
                                disabled={record.isDteCertified}
                            >
                                {descriptions.ACTIONS.CERTIFY_DTE}
                            </Button>
                        ),
                    },
                ];

                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow >
                        <Button
                            size="small"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <DownOutlined rev={undefined} />
                        </Button>
                    </Dropdown>
                </div>

                // return <Space size="middle">
                //     <Tooltip title={"View Sale Detail"} >
                //         <Button type="primary" ghost shape="circle"
                //             icon={<UnorderedListOutlined rev={undefined} />}
                //             onClick={() => handleDetailClick(record.id!)}
                //         />
                //     </Tooltip>
                //     <Tooltip title={record.isDteCertified ? "Get DTE" : "DTE Not Certified"} >
                //         <Button type="primary" ghost shape="circle"
                //             icon={<PrinterOutlined rev={undefined} />}
                //             onClick={() => handleGetDteClick(record.authorization)}
                //             disabled={!record.isDteCertified}
                //         />
                //     </Tooltip>
                // </Space>
            },
        },
    ]

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_SALE_RECORDS_API]),
        saleRecords: state.sale.sales,
        isGettingSaleById: serviceLoading(state, [GET_SALE_BY_ID_API]),
        currentSale: state.sale.currentSale,
    }))

    const handleDateRangesChange = (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const handleActionButtonClick = () => {
        dispatch(getSaleRecordsApi(
            dateRanges.startDate,
            dateRanges.endDate
        ))
    }

    const buildReportData = () => {
        return reduxProps?.saleRecords?.map((reportItem: Sale) => {
            return {
                No: reportItem.id,
                Fecha: formatToDisplayDate(reportItem.datetime),
                NIT: reportItem.invoiceReceiverId,
                Nombre: reportItem.invoiceReceiverName,
                Documento: reportItem.document,
                DTE: reportItem?.authorization || "NA",
                Total: reportItem.amount,
            }
        }) ?? []
    }

    const handleExport = () => {
        if (!reduxProps.saleRecords) {
            return
        }

        const xlsExporter: XlsExporter = new XlsExporter({
            sheetPrefixName: "SalesReport",
            documentName: "SalesReport",
            appendTimestamp: true,
        })

        xlsExporter.exportData(buildReportData())
    }

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Button
                size="small"
                onClick={handleExport}
                disabled={!reduxProps.saleRecords || reduxProps.saleRecords.length === 0}
            >
                {descriptions.EXPORT_DATA}
            </Button>
        </div>
    }

    const renderContent = () => (
        <PageCard
            size="small"
            title={descriptions.TITLE}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.saleRecords ?? []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs(), dayjs()]}
                actionButtonOnClick={handleActionButtonClick}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    )

    return (
        <>
            {renderContent()}
            {
                reduxProps.currentSale && selectedAction === "DETAIL" &&
                <SaleDetailModal
                    sale={reduxProps.currentSale}
                    open={!!reduxProps.currentSale}
                    onAccept={() => { dispatch(clearCurrentSale()); }}
                    onCancel={() => { dispatch(clearCurrentSale()); }}
                />
            }
            {
                reduxProps.currentSale && selectedAction === "RE_CERTIFY" &&
                <ReCertificationDTE
                    sale={reduxProps.currentSale}
                    open={!!reduxProps.currentSale}
                    onAccept={() => {
                        dispatch(clearCurrentSale());
                        dispatch(getSaleRecordsApi(
                            dateRanges.startDate,
                            dateRanges.endDate
                        ))
                    }}
                    onCancel={() => { dispatch(clearCurrentSale()); }}
                />
            }
            <Loader
                isVisible={reduxProps.isGettingSaleById}
            />
        </>
    )
}
