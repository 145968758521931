import { CompanyData } from "./CompanyData"
import { Shortcuts } from "./Shortcuts"
import * as Styles from './styles'

export const Dashboard = () => {

    return (
        <Styles.Container>
            <CompanyData />
            <Shortcuts />
        </Styles.Container>
    )
}
