import { ActionButton } from '../ActionButton'
import { ActionButtonType } from '../ActionButton/types'
import { ButtonsContainer, ButtonFormItem } from './styles'

interface Props {
  cancelText: string
  actionText: string
  actionDisabled?: () => boolean
  onCancel: () => void
}

export const FormActionButtons = (props: Props) => {
  return (
    <ButtonsContainer>
      <ActionButton
        label={props.cancelText}
        onClick={props.onCancel}
        actionButtonType={ActionButtonType.DESTRUCTIVE}
      />
      <ButtonFormItem shouldUpdate>
        {() => (
          <ActionButton
            label={props.actionText}
            htmlType='submit'
            disabled={props.actionDisabled ? props.actionDisabled() : false}
            styles={{ width: '100%' }}
          />
        )}
      </ButtonFormItem>
    </ButtonsContainer >
  )
}