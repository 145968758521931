import { Descriptions, DescriptionsProps, Row } from 'antd';
import { PageCard } from 'src/common/styles/styles';
import { Col18, Col6 } from 'src/components/Columns';
import { Image } from 'antd';
import * as Styles from './styles'
import { Assets } from 'src/common/assets';
import { Company } from 'src/common/models/company';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/reducer';
import { SystemDescriptions } from 'src/common/descriptions';

interface ReduxProps {
    company?: Company
}

export const CompanyData = () => {
    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        company: state.common.company,
    }))

    const descriptions = SystemDescriptions.COMPONENTS.DASHBOARD

    const items: DescriptionsProps['items'] = [
        {
            key: '1',
            label: descriptions.COMPANY_INFORMATION.NIT,
            children: reduxProps.company?.nit,
        },
        {
            key: '2',
            label: descriptions.COMPANY_INFORMATION.PHONE,
            children: '',
        },
        {
            key: '3',
            label: descriptions.COMPANY_INFORMATION.EMAIL,
            children: reduxProps.company?.email,
        },
        {
            key: '4',
            label: descriptions.COMPANY_INFORMATION.ZIPCODE,
            children: reduxProps.company?.zipcode,
        },
        {
            key: '5',
            label: descriptions.COMPANY_INFORMATION.ADDRESS,
            children: `${reduxProps.company?.address}, ${reduxProps.company?.city}, ${reduxProps.company?.state} `,
        },
    ];

    const renderCompanyData = () => (
        <PageCard size="small" title="" style={{ width: '100%' }}>
            <Row>
                <Col6>
                    <Styles.LeftSideContainer>
                        <Image
                            width={125}
                            src={Assets.COMPANY_LOGO_LOCAL}
                            style={{
                                borderRadius: '50%',
                            }}
                            preview={false}
                        />
                        <label>{reduxProps.company?.comercialName}</label>
                    </Styles.LeftSideContainer>
                </Col6>
                <Col18>
                    <Descriptions title={descriptions.COMPANY_INFORMATION.TITLE} items={items} />
                </Col18>
            </Row>
        </PageCard>
    )

    return (
        renderCompanyData()
    )
}
