import { CreatePurchaseSuccessAction, ErrorAction, GetPurchaseByIdSuccessAction, GetPurchasePaymentRecordsSuccessAction, GetPurchaseRecordsSuccessAction } from "./action";
import { PurchaseError, PurchaseErrorOrigin } from "./types";
import { CLEAN_PURCHASE_PAYMENTS, CREATE_PURCHASE_API, CREATE_PURCHASE_API_ERROR, CREATE_PURCHASE_API_SUCCESS, CREATE_PURCHASE_PAYMENT_API, CREATE_PURCHASE_PAYMENT_API_SUCCESS, GET_PURCHASE_BY_ID_API, GET_PURCHASE_BY_ID_API_ERROR, GET_PURCHASE_BY_ID_API_SUCCESS, GET_PURCHASE_PAYMENTS_API, GET_PURCHASE_PAYMENTS_API_ERROR, GET_PURCHASE_PAYMENTS_API_SUCCESS, GET_PURCHASE_RECORDS_API, GET_PURCHASE_RECORDS_API_ERROR, GET_PURCHASE_RECORDS_API_SUCCESS } from "./actionTypes";
import { Purchase } from "src/common/models/purchase";
import { parseDataKey } from "src/common/util";
import { PurchasePayment } from "src/common/models/purchasePayment";

export interface PurchaseState {
    error?: PurchaseError
    createPurchaseSuccess: boolean
    upsertedPurchase?: Purchase
    purchases?: Purchase[]
    currentPurchase?: Purchase
    createPurchasePaymentSuccess: boolean
    purchasePayments?: PurchasePayment[]
}

const initialState: PurchaseState = {
    createPurchaseSuccess: false,
    createPurchasePaymentSuccess: false,
}

const purchaseReducer = (state: PurchaseState = initialState, action: any): PurchaseState => {
    switch (action.type) {
        case CREATE_PURCHASE_API: {
            return {
                ...state,
                error: undefined,
                createPurchaseSuccess: false,
            }
        }
        case CREATE_PURCHASE_API_SUCCESS: {
            const successAction = action as CreatePurchaseSuccessAction;

            return {
                ...state,
                upsertedPurchase: successAction.purchase,
                createPurchaseSuccess: true,
            }
        }
        case CREATE_PURCHASE_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: PurchaseErrorOrigin.CREATE_PURCHASE,
                    detail: errorAction.error
                }
            }
        }
        case GET_PURCHASE_RECORDS_API: {
            return {
                ...state,
                error: undefined
            }
        }
        case GET_PURCHASE_RECORDS_API_SUCCESS: {
            const successAction = action as GetPurchaseRecordsSuccessAction;

            return {
                ...state,
                purchases: parseDataKey(successAction.purchases),
            }
        }
        case GET_PURCHASE_RECORDS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: PurchaseErrorOrigin.GET_PURCHASE_RECORDS,
                    detail: errorAction.error
                }
            }
        }
        case GET_PURCHASE_BY_ID_API: {
            return {
                ...state,
                error: undefined
            }
        }
        case GET_PURCHASE_BY_ID_API_SUCCESS: {
            const successAction = action as GetPurchaseByIdSuccessAction;

            return {
                ...state,
                currentPurchase: successAction.purchase,
            }
        }
        case GET_PURCHASE_BY_ID_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: PurchaseErrorOrigin.GET_PURCHASE_BY_ID,
                    detail: errorAction.error
                }
            }
        }
        case CREATE_PURCHASE_PAYMENT_API: {
            return {
                ...state,
                error: undefined,
                createPurchaseSuccess: false,
            }
        }
        case CREATE_PURCHASE_PAYMENT_API_SUCCESS: {
            return {
                ...state,
                createPurchasePaymentSuccess: true,
            }
        }
        case CREATE_PURCHASE_PAYMENT_API_SUCCESS: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: PurchaseErrorOrigin.CREATE_PURCHASE_PAYMENT,
                    detail: errorAction.error
                }
            }
        }
        case GET_PURCHASE_PAYMENTS_API: {
            return {
                ...state,
                error: undefined,
                purchasePayments: undefined,
            }
        }
        case GET_PURCHASE_PAYMENTS_API_SUCCESS: {
            const successAction = action as GetPurchasePaymentRecordsSuccessAction;

            return {
                ...state,
                purchasePayments: parseDataKey(successAction.purchasePayments),
            }
        }
        case GET_PURCHASE_PAYMENTS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: PurchaseErrorOrigin.GET_PURCHASE_PAYMENTS,
                    detail: errorAction.error
                }
            }
        }
        case CLEAN_PURCHASE_PAYMENTS: {
            return {
                ...state,
                purchasePayments: undefined,
            }
        }
    }
    return state;
};

export default purchaseReducer;