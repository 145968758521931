import { Languages } from "src/common/descriptions/types";
import { CreateSaleDescriptions } from "./types";

const DescriptionsEnUs: CreateSaleDescriptions = {
    TITLE: "Create Sale",
    SAVE_BUTTON: "Save",
    CANCEL_BUTTON: "Cancel",
    CONFIRM_MODAL: {
        TITLE: 'Save Sale?',
        DESCRIPTION: "Operation will not be reverted.",
        ACTION_BUTTON: "Save",
        CANCEL_BUTTON: "Go Back",
    },
    CANCEL_MODAL: {
        TITLE: "Cancel Sale input?",
        DESCRIPTION: "All data will be lost.",
        ACTION_BUTTON: "Yes, Cancel",
        CANCEL_BUTTON: "Keep working",
    },
    SUCCESS_MODAL: {
        TITLE: "Success",
        PRIMARY_LABEL: "Accept",
        SECONDARY_LABEL: "Print",
        DESCRIPTION: "Sale Created",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} is required.`,
        SAVE_ACTION: "",
        UNKNOWN: "An error ocurred while processing request, please contact support."
    },
    FORM: {
        CUSTOMER: {
            LABEL: "Customer",
            PLACEHOLDER: "Select Customer",
        },
        SHIP_TO: {
            LABEL: "Ship to",
            PLACEHOLDER: "Ship to",
        },
        DOCUMENT: {
            LABEL: "Document",
            PLACEHOLDER: "Input document",
        },
        DATETIME: {
            LABEL: "Datetime",
            PLACEHOLDER: "Select",
        },
        TYPE: {
            LABEL: "Condition",
            PLACEHOLDER: "Select",
        },
        EXPIRATION_DATE: {
            LABEL: "Expiration",
            PLACEHOLDER: "Select",
        },
        PAYMENT_STATUS: {
            LABEL: "Payment Status",
            PLACEHOLDER: "Select",
        },
        INVOICE_RECEIVER_ID: {
            LABEL: "NIT/CUI",
            PLACEHOLDER: "Input Invoice Id",
        },
        INVOICE_RECEIVER_NAME: {
            LABEL: "Name",
            PLACEHOLDER: "Invoice Name",
        },
        INVOICE_RECEIVER_ADDRESS: {
            LABEL: "Address",
            PLACEHOLDER: "Invoice Address",
        },
        PRODUCTS: {
            LABEL: "Products",
            PLACEHOLDER: "Select",
        },
        INVOICE_AUTH: {
            LABEL: "Authorization",
            PLACEHOLDER: "Authorization",
        },
        INVOICE_SERIES: {
            LABEL: "Series",
            PLACEHOLDER: "Series",
        },
        INVOICE_NUMBER: {
            LABEL: "Number",
            PLACEHOLDER: "Number",
        },
        TYPE_CASH: "Cash",
        TYPE_CREDIT: "Credit",
        PAYMENT_PAYED: "Payed",
        PAYMENT_PENDING: "Pending",
        SUBTOTAL: "Subtotal",
        DISSCOUNT: "Disscount",
        TOTAL: "Total",
        ERRORS: {
            INVOICE_AMOUNT_MIN_LIMIT: "Maximum amoount to Invoice to CF is 2,499.99",
            DTE_NOT_CERTIFIED: "An error ocurred at certify DTE.",
            TITLE: "An error ocurred",
        },
    },
    MAIN_ACTION_TITLE: "Creating Sale and Getting FEL authorization",
    MAIN_ACTION_DESCRIPTION: "Please wait...",
    MAIN_ACTION_TITLE_NO_INVOICE: "Creating Sale",
}

const DescriptionsEsUs: CreateSaleDescriptions = {
    TITLE: "Crear Venta",
    SAVE_BUTTON: "Guardar",
    CANCEL_BUTTON: "Cancelar",
    CONFIRM_MODAL: {
        TITLE: 'Guardar Venta?',
        DESCRIPTION: "La operación no podrá ser revertida.",
        ACTION_BUTTON: "Guardar",
        CANCEL_BUTTON: "Regresar",
    },
    CANCEL_MODAL: {
        TITLE: "Cancelar Ingreso de Venta?",
        DESCRIPTION: "La información ingresada se perderá.",
        ACTION_BUTTON: "Si, Cancelar",
        CANCEL_BUTTON: "Seguir trabajando",
    },
    SUCCESS_MODAL: {
        TITLE: "Éxito",
        PRIMARY_LABEL: "Aceptar",
        SECONDARY_LABEL: "Imprimir",
        DESCRIPTION: "Venta Creada",
    },
    ERRORS: {
        REQUIRED_FIELD: (fieldName: string) => `${fieldName} es requerido.`,
        SAVE_ACTION: "",
        UNKNOWN: "Ha ocurrido un error al procesar la petición, contactar a soporte."
    },
    FORM: {
        CUSTOMER: {
            LABEL: "Cliente",
            PLACEHOLDER: "Seleccione cliente",
        },
        SHIP_TO: {
            LABEL: "Enviar a",
            PLACEHOLDER: "Enviar a",
        },
        DOCUMENT: {
            LABEL: "Documento",
            PLACEHOLDER: "Ingrese documento",
        },
        DATETIME: {
            LABEL: "Fecha",
            PLACEHOLDER: "Seleccione fecha",
        },
        TYPE: {
            LABEL: "Condición",
            PLACEHOLDER: "Seleccione",
        },
        EXPIRATION_DATE: {
            LABEL: "Vencimiento",
            PLACEHOLDER: "Seleccione",
        },
        PAYMENT_STATUS: {
            LABEL: "Estado Pago",
            PLACEHOLDER: "Seleccione",
        },
        INVOICE_RECEIVER_ID: {
            LABEL: "NIT/CUI",
            PLACEHOLDER: "Ingrese",
        },
        INVOICE_RECEIVER_NAME: {
            LABEL: "Nombre",
            PLACEHOLDER: "Nombre",
        },
        INVOICE_RECEIVER_ADDRESS: {
            LABEL: "Dirección",
            PLACEHOLDER: "Dirección",
        },
        INVOICE_AUTH: {
            LABEL: "Autorización",
            PLACEHOLDER: "Autorización",
        },
        INVOICE_SERIES: {
            LABEL: "Serie",
            PLACEHOLDER: "Serie",
        },
        INVOICE_NUMBER: {
            LABEL: "Número",
            PLACEHOLDER: "Número",
        },
        PRODUCTS: {
            LABEL: "Productos",
            PLACEHOLDER: "Seleccione",
        },
        TYPE_CASH: "Contado",
        TYPE_CREDIT: "Crédito",
        PAYMENT_PAYED: "Pagado",
        PAYMENT_PENDING: "Pendiente",
        SUBTOTAL: "Subtotal",
        DISSCOUNT: "Descuento",
        TOTAL: "Total",
        ERRORS: {
            INVOICE_AMOUNT_MIN_LIMIT: "Monto máximo para facturar a CF es 2,499.99",
            DTE_NOT_CERTIFIED: "Ha ocurrido un error al certificar DTE.",
            TITLE: "Ha ocurrido un error",
        },
    },
    MAIN_ACTION_TITLE: "Creando Compra y Obteniendo autorización FEL",
    MAIN_ACTION_DESCRIPTION: "Por favor espera...",
    MAIN_ACTION_TITLE_NO_INVOICE: "Guardando Compra",
}

export const createSaleDictionary = {}
createSaleDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
createSaleDictionary[Languages.SPANISH_US] = DescriptionsEsUs