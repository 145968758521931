import { Form, Input, Row, Select } from 'antd'
import { useSelector } from 'react-redux'
import { SystemDescriptions } from 'src/common/descriptions'
import { ProductMeassure } from 'src/common/models/productMeassure'
import { Col12, Col24 } from 'src/components/Columns'
import { CurrencyInput } from 'src/components/CurrencyInput'
import { RootState } from 'src/state/reducer'
import * as Styles from './styles'
import { ProductPresentation } from 'src/common/models/productPresentation'
import { v4 as uuid } from 'uuid'
import { useEffect } from 'react'
import { amountValidator, roundDecimalNumber } from 'src/common/util'
import { FormActionButtons } from 'src/components/FormActionButtons'

interface Props {
    open: boolean
    onAccept: (productPresentation: Partial<ProductPresentation>) => void
    onCancel: () => void
    initialValues?: Partial<ProductPresentation>
}

interface ReduxProps {
    meassures?: ProductMeassure[]
}

export const CreatePresentation = (props: Props) => {

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            qty: props?.initialValues?.qty ?? 1,
            meassureId: props?.initialValues?.meassureId ? `${props?.initialValues?.meassureId}` : undefined,
            unitPrice: props?.initialValues?.unitPrice ?? 0,
            salePrice: props?.initialValues?.salePrice ?? 0,
            barcode: props?.initialValues?.barcode ?? '',
        })
    }, [props.open])

    const descriptions = SystemDescriptions.PAGES.PRODUCT.CREATE_PRODUCT

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        meassures: state.productSettings.meassures,
    }))

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const buildRequiredMessageErr = (fieldName: string): string => descriptions.ERRORS.REQUIRED_FIELD(fieldName)

    const onFinish = () => {
        const { meassureId, qty, unitPrice, salePrice, barcode } = form.getFieldsValue()

        const presentation: Partial<ProductPresentation> = {
            meassureId: parseInt(meassureId),
            meassure: reduxProps?.meassures?.find(candidate => candidate.id == meassureId)?.name,
            qty: parseInt(qty),
            unitPrice: parseFloat(unitPrice),
            salePrice: parseFloat(salePrice),
            ref: props?.initialValues ? props.initialValues.ref : uuid(),
            isDefault: false,
            barcode: barcode || '',
        }

        props.onAccept(presentation)
    }

    const getButtonStatus = (): boolean => {
        return !form.isFieldsTouched(["meassure", "qty", "unitPrice", "salePrice"]) ||
            form.getFieldsError().filter(({ errors }) => errors.length)
                .length > 0
    }

    const renderButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL_BUTTON}
            actionText={descriptions.SAVE_BUTTON}
            onCancel={props.onCancel}
            actionDisabled={getButtonStatus}
        />
    )

    const handleUnitPriceChange = (e) => {
        const unitPrice = parseFloat(e.target.value)
        const { qty } = form.getFieldsValue()
        const salePrice = roundDecimalNumber(unitPrice * parseInt(qty))
        form.setFieldsValue({
            salePrice,
        })
    }

    const handleSalePriceChange = (e) => {
        const salePrice = parseFloat(e.target.value)
        const { qty } = form.getFieldsValue()
        const unitPrice = roundDecimalNumber(salePrice / parseInt(qty), 4)
        form.setFieldsValue({
            unitPrice,
        })
    }

    const handleQtyChange = (e) => {
        const qty = parseInt(e.target.value)
        const { unitPrice, salePrice } = form.getFieldsValue()

        if (unitPrice) {
            const salePrice = roundDecimalNumber(unitPrice * qty)
            form.setFieldsValue({
                salePrice,
            })
        } else if (salePrice) {
            const unitPrice = roundDecimalNumber(salePrice / qty)
            form.setFieldsValue({
                unitPrice,
            })
        }
    }

    return (
        <Styles.ModalDialog
            title={descriptions.CREATE_PRESENTATION.TITLE}
            centered
            open={props.open}
            onCancel={props.onCancel}
            footer={<></>}
        >
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                autoComplete={'none'}
                onFinish={onFinish}
            >
                <Row gutter={[8, 0]} >
                    <Col12>
                        <Form.Item
                            label={descriptions.CREATE_PRESENTATION.FORM.MEASSURE.LABEL}
                            name={"meassureId"}
                            rules={[{ required: true, message: buildRequiredMessageErr(descriptions.CREATE_PRESENTATION.FORM.MEASSURE.LABEL) }]}
                            validateTrigger="onBlur"
                        >
                            <Select
                                options={reduxProps.meassures?.map(meassure => ({
                                    value: `${meassure.id}`,
                                    label: meassure.name,
                                }))}
                                placeholder={descriptions.CREATE_PRESENTATION.FORM.MEASSURE.PLACEHOLDER}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                            />
                        </Form.Item>
                    </Col12>
                    <Col12>
                        <Form.Item
                            label={descriptions.CREATE_PRESENTATION.FORM.QTY.LABEL}
                            name={"qty"}
                            rules={[
                                {
                                    required: true,
                                    message: buildRequiredMessageErr(descriptions.CREATE_PRESENTATION.FORM.QTY.LABEL)
                                },
                                {
                                    validator: (_, value) => amountValidator(value)
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <Input
                                placeholder={descriptions.CREATE_PRESENTATION.FORM.QTY.PLACEHOLDER}
                                onChange={handleQtyChange}
                            />
                        </Form.Item>
                    </Col12>
                    <Col24>
                        <Form.Item
                            label={descriptions.CREATE_PRESENTATION.FORM.BARCODE.LABEL}
                            name={"barcode"}
                            validateTrigger="onBlur"
                        >
                            <Input
                                placeholder={descriptions.CREATE_PRESENTATION.FORM.BARCODE.PLACEHOLDER}
                            />
                        </Form.Item>
                    </Col24>
                    <Col12>
                        <Form.Item
                            label={descriptions.CREATE_PRESENTATION.FORM.UNIT_PRICE.LABEL}
                            name={"unitPrice"}
                            rules={[
                                {
                                    required: true,
                                    message: buildRequiredMessageErr(descriptions.CREATE_PRESENTATION.FORM.UNIT_PRICE.LABEL)
                                },
                                {
                                    validator: (_, value) => amountValidator(value)
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <CurrencyInput
                                placeholder={descriptions.CREATE_PRESENTATION.FORM.UNIT_PRICE.PLACEHOLDER}
                                onChange={handleUnitPriceChange}
                            />
                        </Form.Item>
                    </Col12>
                    <Col12>
                        <Form.Item
                            label={descriptions.CREATE_PRESENTATION.FORM.SALE_PRICE.LABEL}
                            name={"salePrice"}
                            rules={[
                                {
                                    required: true,
                                    message: buildRequiredMessageErr(descriptions.CREATE_PRESENTATION.FORM.SALE_PRICE.LABEL)
                                },
                                {
                                    validator: (_, value) => amountValidator(value)
                                },
                            ]}
                            validateTrigger="onBlur"
                        >
                            <CurrencyInput
                                placeholder={descriptions.CREATE_PRESENTATION.FORM.SALE_PRICE.PLACEHOLDER}
                                onChange={handleSalePriceChange}
                            />
                        </Form.Item>
                    </Col12>
                </Row>
                {renderButtons()}
            </Form>
        </Styles.ModalDialog>
    )
}
