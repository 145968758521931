import { searchableTableUtils } from "src/components/SearchableTable/utils";

export enum ColumnsKeys {
    KEY = 'key',
    PRODUCT_ID = 'productId',
    MEASSURE = 'meassure',
    DESCRIPTION = 'product',
    QTY = 'qty',
}

export const TABLE_COLUMNS: any = [
    {
        title: "PRODUCT ID",
        dataIndex: ColumnsKeys.PRODUCT_ID,
        key: ColumnsKeys.PRODUCT_ID,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: "MEASSURE",
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: "DESCRIPTION",
        dataIndex: ColumnsKeys.DESCRIPTION,
        key: ColumnsKeys.DESCRIPTION,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: "QTY",
        dataIndex: ColumnsKeys.QTY,
        key: ColumnsKeys.QTY,
        align: searchableTableUtils.alignment.centerAlign,
    },
];