// import { Sale } from "src/common/models/sale"
// import { SalePayment } from "src/common/models/salePayment"
// import { User } from "src/common/models/user"

// interface ReduxProps {
//     isLoadingData: boolean
//     accountReceivables?: Sale[]
//     isGettingSale: boolean
//     currentSale?: Sale
//     isCreatingPurchasePayment: boolean
//     createPurchasePaymentSuccess: boolean
//     user?: User
//     isGettingPurchasePayments: boolean
//     purchasePayments?: SalePayment[]
// }

export const AccountReceivables = () => {

    const renderContent = () => {
        <>No</>
    }

    return (
        <>
            {renderContent()}
            {/* {
                selectedPurchase &&
                <PurchaseDetailModal
                    purchase={selectedPurchase}
                    open={!!selectedPurchase}
                    onAccept={() => { setSelectedPurchase(undefined) }}
                    onCancel={() => { setSelectedPurchase(undefined) }}
                />
            }
            <Loader
                isVisible={reduxProps.isGettingPurchase || reduxProps.isGettingPurchasePayments}
            /> */}
            {/* {
                paymentTransaction &&
                <PaymentModal
                    open={paymentModalOpen}
                    type={ProductTransactionType.PURCHASE}
                    onAccept={() => { setPaymentModalOpen(false) }}
                    onCancel={() => { setPaymentModalOpen(false) }}
                    data={paymentTransaction}
                />
            } */}
            {/* <PurchasePaymentRecords
                purchasePayments={reduxProps.purchasePayments || []}
                open={!!reduxProps.purchasePayments}
                onAccept={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
                onCancel={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
            /> */}
        </>
    )
}
