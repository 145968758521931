import { Action } from 'redux'
import * as Actions from './actionTypes'
import { GetPurchaseRecordsFilters, Purchase } from 'src/common/models/purchase'
import { TransactionPayment } from 'src/common/models/transactionPayment'
import { GetPurchasePaymentRecordsFilters, PurchasePayment } from 'src/common/models/purchasePayment'

export interface CreatePurchaseAction extends Action {
    purchase: Purchase
}
export interface CreatePurchaseSuccessAction extends Action {
    purchase: Purchase
}
export interface ErrorAction extends Action {
    error?
}
export const createPurchaseApi = (purchase: Purchase): CreatePurchaseAction => ({
    type: Actions.CREATE_PURCHASE_API,
    purchase,
})
export const createPurchaseSuccess = (purchase: Purchase): CreatePurchaseSuccessAction => ({
    type: Actions.CREATE_PURCHASE_API_SUCCESS,
    purchase,
})
export const createPurchaseError = (error?): ErrorAction => ({
    type: Actions.CREATE_PURCHASE_API_ERROR,
    error,
})

export interface GetPurchaseRecordsAction extends Action {
    filters?: GetPurchaseRecordsFilters
}
export interface GetPurchaseRecordsSuccessAction extends Action {
    purchases: Purchase[]
}
export const getPurchaseRecordsApi = (filters?: GetPurchaseRecordsFilters): GetPurchaseRecordsAction => ({
    type: Actions.GET_PURCHASE_RECORDS_API,
    filters,
})
export const getPurchaseRecordsSuccess = (purchases: Purchase[]): GetPurchaseRecordsSuccessAction => ({
    type: Actions.GET_PURCHASE_RECORDS_API_SUCCESS,
    purchases,
})
export const getPurchaseRecordsError = (error?): ErrorAction => ({
    type: Actions.GET_PURCHASE_RECORDS_API_ERROR,
    error,
})

export interface GetPurchaseByIdAction extends Action {
    purchaseId: number
}
export interface GetPurchaseByIdSuccessAction extends Action {
    purchase: Purchase
}
export const getPurchaseByIdApi = (purchaseId: number): GetPurchaseByIdAction => ({
    type: Actions.GET_PURCHASE_BY_ID_API,
    purchaseId
})
export const getPurchaseByIdSuccess = (purchase: Purchase): GetPurchaseByIdSuccessAction => ({
    type: Actions.GET_PURCHASE_BY_ID_API_SUCCESS,
    purchase,
})
export const getPurchaseByIdError = (error?): ErrorAction => ({
    type: Actions.GET_PURCHASE_BY_ID_API_ERROR,
    error,
})

export interface CreatePurchasePaymentAction extends Action {
    paymentData: TransactionPayment
}
export interface CreatePurchasePaymentSuccessAction extends Action {
    paymentData: TransactionPayment
}
export const createPurchasePaymentApi = (paymentData: TransactionPayment): CreatePurchasePaymentAction => ({
    type: Actions.CREATE_PURCHASE_PAYMENT_API,
    paymentData,
})
export const createPurchasePaymentSuccess = (paymentData: TransactionPayment): CreatePurchasePaymentSuccessAction => ({
    type: Actions.CREATE_PURCHASE_PAYMENT_API_SUCCESS,
    paymentData,
})
export const createPurchasePaymentError = (error?): ErrorAction => ({
    type: Actions.CREATE_PURCHASE_PAYMENT_API_ERROR,
    error,
})

export interface GetPurchasePaymentRecordsAction extends Action {
    filters?: GetPurchasePaymentRecordsFilters
}
export interface GetPurchasePaymentRecordsSuccessAction extends Action {
    purchasePayments: PurchasePayment[]
}
export const getPurchasePaymentRecordsApi = (filters?: GetPurchasePaymentRecordsFilters): GetPurchasePaymentRecordsAction => ({
    type: Actions.GET_PURCHASE_PAYMENTS_API,
    filters,
})
export const getPurchasePaymentRecordsSuccess = (purchasePayments: PurchasePayment[]): GetPurchasePaymentRecordsSuccessAction => ({
    type: Actions.GET_PURCHASE_PAYMENTS_API_SUCCESS,
    purchasePayments,
})
export const getPurchasePaymentRecordsError = (error?): ErrorAction => ({
    type: Actions.GET_PURCHASE_PAYMENTS_API_ERROR,
    error,
})

export const cleanPurchasePaymentRecordsApi = (): Action => ({
    type: Actions.CLEAN_PURCHASE_PAYMENTS,
})