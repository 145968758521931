import { Languages } from "src/common/descriptions/types";
import { SaleRecordsDescriptions } from "./types";

const DescriptionsEnUs: SaleRecordsDescriptions = {
    TITLE: "Sale Records",
    TABLE: {
        ACTION_BUTTON: "Get Records",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Datetime",
        CUSTOMER: "Customer",
        DOCUMENT: "Document",
        TOTAL: "Total",
        OUTSTANDING: "Outstanding",
        DTE_CERTIFIED: "Dte Certified",
        STATUS: "Status",
        ACTIONS: "Actions",
        INVOICE_RECEIVER_ID: "NIT/CUI",
    },
    STATUS_PAYED: "Payed",
    STATUS_PENDING: "Pending",
    ACTIONS: {
        VIEW_DETAIL: "View Detail",
        GET_DTE: "Get DTE",
        CERTIFY_DTE: "Certify DTE",
    },
    DETAIL_TITLE: "Sale Detail",
    EXPORT_DATA: "Export Data",
}

const DescriptionsEsUs: SaleRecordsDescriptions = {
    TITLE: "Registro de Ventas",
    TABLE: {
        ACTION_BUTTON: "Ver Registros",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando datos",
    },
    TABLE_COLUMNS: {
        KEY: "No",
        DATETIME: "Fecha",
        CUSTOMER: "Cliente",
        DOCUMENT: "Documento",
        TOTAL: "Total",
        OUTSTANDING: "Saldo",
        DTE_CERTIFIED: "DTE Certificado",
        STATUS: "Estado",
        ACTIONS: "Acciones",
        INVOICE_RECEIVER_ID: "NIT/CUI",
    },
    STATUS_PAYED: "Pagada",
    STATUS_PENDING: "Pendiente",
    ACTIONS: {
        VIEW_DETAIL: "Ver Detalle",
        GET_DTE: "Obtener DTE",
        CERTIFY_DTE: "Certificar DTE",
    },
    DETAIL_TITLE: "Detalle de Venta",
    EXPORT_DATA: "Exportar Datos",
}

export const saleRecordsDictionary = {}
saleRecordsDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
saleRecordsDictionary[Languages.SPANISH_US] = DescriptionsEsUs