import { getCommonPagesDescriptions } from "./Common"
import { getAddProductDescriptions } from "./components/AddProduct"
import { getBreadcrumbDescriptions } from "./components/breadcrumb"
import { getDashboardDescriptions } from "./components/Dashboard"
import { getFooterDescriptions } from "./components/footer"
import { getNavbarDescriptions } from "./components/navbar"
import { getPaymentModalDescriptions } from "./components/PaymentModal"
import { getProductTransactionsDescriptions } from "./components/ProductTransactionModal"
import { getSearchableTableDescriptions } from "./components/searchableTable"
import { getSidebarDescriptions } from "./components/Sidebar"
import { getProductsDescriptions } from "./Pages/Product"
import { getPurchaseDescriptions } from "./Pages/Purchase"
import { getReportsDescriptions } from "./Pages/Reports"
import { getSaleDescriptions } from "./Pages/Sale"
import { getSecurityDescriptions } from "./Pages/security"
import { DefaultSettings, Descriptions } from "./types"

export const SystemDescriptions: Descriptions = {
    PAGES: {
        PRODUCT: getProductsDescriptions(DefaultSettings.SYSTEM_LANG),
        SECURITY: getSecurityDescriptions(DefaultSettings.SYSTEM_LANG),
        COMMON: getCommonPagesDescriptions(DefaultSettings.SYSTEM_LANG),
        PURCHASE: getPurchaseDescriptions(DefaultSettings.SYSTEM_LANG),
        SALE: getSaleDescriptions(DefaultSettings.SYSTEM_LANG),
        REPORTS: getReportsDescriptions(DefaultSettings.SYSTEM_LANG),
    },
    COMPONENTS: {
        BREADCRUMB: getBreadcrumbDescriptions(DefaultSettings.SYSTEM_LANG),
        FOOTER: getFooterDescriptions(DefaultSettings.SYSTEM_LANG),
        NAVBAR: getNavbarDescriptions(DefaultSettings.SYSTEM_LANG),
        SIDEBAR: getSidebarDescriptions(DefaultSettings.SYSTEM_LANG),
        SEARCHABLE_TABLE: getSearchableTableDescriptions(DefaultSettings.SYSTEM_LANG),
        ADD_PRODUCT: getAddProductDescriptions(DefaultSettings.SYSTEM_LANG),
        PRODUCT_TRANSACTION: getProductTransactionsDescriptions(DefaultSettings.SYSTEM_LANG),
        DASHBOARD: getDashboardDescriptions(DefaultSettings.SYSTEM_LANG),
        MODAL_PAYMENT: getPaymentModalDescriptions(DefaultSettings.SYSTEM_LANG),
    }
}