import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { TABLE_COLUMNS } from "./config"
import { WarehouseStock as IWarehouseStock } from "src/common/models/warehouseStock"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_WAREHOUSE_STOCK_API } from "../state/actionTypes"
import { useEffect } from "react"
import { getWarehouseStockApi } from "../state/action"
import { Button } from "antd"
import * as XLSX from 'xlsx';
import moment from "moment"
import { SystemDescriptions } from "src/common/descriptions"

interface ReduxProps {
    isLoadingData: boolean
    warehouseStock: IWarehouseStock[]
}

export const WarehouseStock = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getWarehouseStockApi(1))
    }, [])

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_WAREHOUSE_STOCK_API]),
        warehouseStock: state.product.warehouseStock || [],
    }))


    const descriptions = SystemDescriptions.PAGES.PRODUCT.WAREHOUSE_STOCK

    const buildReportData = () => {
        return reduxProps?.warehouseStock?.map((reportItem: IWarehouseStock) => {
            return {
                ID: 0,
                PRODUCT: reportItem.product,
                MEASSURE: reportItem.meassure,
                STOCK: reportItem.stock,
            }
        }) ?? []
    }

    const handleExport = () => {
        if (!reduxProps.warehouseStock) {
            return
        }

        const workbook = XLSX.utils.book_new();

        const worksheet = XLSX.utils.json_to_sheet(buildReportData());
        XLSX.utils.book_append_sheet(workbook, worksheet, "WarehouseStock");

        const excelBuffer = XLSX.write(workbook, { type: 'buffer', bookType: 'xls' });
        const dataUrl = window.URL.createObjectURL(
            new Blob([excelBuffer], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = dataUrl;
        link.setAttribute('download', `STOCK_${moment().unix()}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Button
                size="small"
                onClick={handleExport}
            >
                {descriptions.EXPORT}
            </Button>
        </div>
    }

    const renderContent = () => (
        <PageCard size="small" title={descriptions.TITLE} extra={renderExtraContent()}>
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.warehouseStock}
                tableColumns={TABLE_COLUMNS}
                showLoader={reduxProps.isLoadingData}
            />
        </PageCard >
    )

    return (
        renderContent()
    )
}
