import { Languages } from "src/common/descriptions/types";
import { WarehouseStockDescriptions } from "./types";

const DescriptionsEnUs: WarehouseStockDescriptions = {
    TITLE: "Warehouse Stock",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Search",
        LOADING_DATA: "Loading data...",
    },
    TABLE_COLUMNS: {
        ID: "ID",
        DESCRIPTION: "Description",
        MEASSURE: "Meassure",
        STOCK: "Stock",
    },
    EXPORT: "Export Data",
}

const DescriptionsEsUs: WarehouseStockDescriptions = {
    TITLE: "Existencias de Producto",
    TABLE: {
        ACTION_BUTTON: "",
        SEARCH_PLACEHOLDER: "Buscar",
        LOADING_DATA: "Cargando información...",
    },
    TABLE_COLUMNS: {
        ID: "ID",
        DESCRIPTION: "Descripción",
        MEASSURE: "Presentación",
        STOCK: "Existencia",
    },
    EXPORT: "Exportar Datos",
}

export const warehouseStockDictionary = {}
warehouseStockDictionary[Languages.ENGLISH_US] = DescriptionsEnUs
warehouseStockDictionary[Languages.SPANISH_US] = DescriptionsEsUs