import { SystemDescriptions } from "src/common/descriptions";
import { searchableTableUtils } from "src/components/SearchableTable/utils";

const descriptions = SystemDescriptions.PAGES.PRODUCT.WAREHOUSE_STOCK

export enum ColumnsKeys {
    KEY = 'key',
    PRODUCT_ID = 'productId',
    PRODUCT = 'product',
    MEASSURE = 'meassure',
    STOCK = 'stock',
    ACTIONS = 'actions',
}

export const TABLE_COLUMNS: any = [
    {
        title: descriptions.TABLE_COLUMNS.ID,
        dataIndex: ColumnsKeys.KEY,
        key: ColumnsKeys.KEY,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.DESCRIPTION,
        dataIndex: ColumnsKeys.PRODUCT,
        key: ColumnsKeys.PRODUCT,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.MEASSURE,
        dataIndex: ColumnsKeys.MEASSURE,
        key: ColumnsKeys.MEASSURE,
        align: searchableTableUtils.alignment.centerAlign,
    },
    {
        title: descriptions.TABLE_COLUMNS.STOCK,
        dataIndex: ColumnsKeys.STOCK,
        key: ColumnsKeys.STOCK,
        align: searchableTableUtils.alignment.centerAlign,
    },
];