import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { TABLE_COLUMNS, summaryColumns } from "./config"
import { Purchase } from "src/common/models/purchase"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_PURCHASE_BY_ID_API, GET_PURCHASE_PAYMENTS_API, GET_PURCHASE_RECORDS_API } from "../state/actionTypes"
import { useEffect, useState } from "react"
import { cleanPurchasePaymentRecordsApi, getPurchaseByIdApi, getPurchasePaymentRecordsApi, getPurchaseRecordsApi } from "../state/action"
import { DateRanges } from "src/components/SearchableTable/types"
import moment from "moment"
import dayjs from 'dayjs'
import { SystemConstants } from "src/common/constants"
import { SystemDescriptions } from "src/common/descriptions"
import { searchableTableUtils } from "src/components/SearchableTable/utils"
import { Button, Dropdown, MenuProps } from "antd"
import { DownOutlined } from '@ant-design/icons'
import { Loader } from "src/components/Loader"
import { PurchaseDetailModal } from "./PurchaseDetailModal"
import { PurchasePaymentRecords } from "./PurchasePaymentRecords"
import { PurchasePayment } from "src/common/models/purchasePayment"

interface ReduxProps {
    isLoadingData: boolean
    purchaseRecords?: Purchase[]
    isGettingPurchase: boolean
    isGettingPurchasePayments: boolean
    currentPurchase?: Purchase
    purchasePayments?: PurchasePayment[]
}

export const PurchaseRecords = () => {
    const dispatch = useDispatch()

    const [selectedPurchase, setSelectedPurchase] = useState<Purchase | undefined>(undefined)

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_PURCHASE_RECORDS_API]),
        purchaseRecords: state.purchase.purchases,
        isGettingPurchase: serviceLoading(state, [GET_PURCHASE_BY_ID_API]),
        currentPurchase: state.purchase.currentPurchase,
        purchasePayments: state.purchase.purchasePayments,
        isGettingPurchasePayments: serviceLoading(state, [GET_PURCHASE_PAYMENTS_API]),
    }))

    useEffect(() => {
        dispatch(getPurchaseRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }, [])

    useEffect(() => {
        if (reduxProps.currentPurchase) {
            setSelectedPurchase(reduxProps.currentPurchase)
        }
    }, [reduxProps.currentPurchase])

    const descriptions = SystemDescriptions.PAGES.PURCHASE.ACCOUNT_PAYABLES

    const handleDateRangesChange = async (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const tblColumns = [
        ...TABLE_COLUMNS,
        {
            title: descriptions.TABLE_COLUMNS.ACTIONS,
            dataIndex: "actions",
            key: "actions",
            align: searchableTableUtils.alignment.centerAlign,
            render: (_, record: Purchase) => {
                const handleDetailClick = (purchaseId: number) => {
                    dispatch(getPurchaseByIdApi(purchaseId))
                }

                const handleViewPaymentRecordsClick = (purchaseId: number) => {
                    dispatch(getPurchasePaymentRecordsApi({
                        purchaseId,
                    }))
                }

                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleDetailClick(record.id!)}
                            >
                                {descriptions.ACTIONS.VIEW_DETAIL}
                            </Button>
                        ),
                    },
                    {
                        key: '2',
                        label: (
                            <Button
                                style={{ margin: 0, width: '100%', textAlign: 'left' }}
                                type='text'
                                onClick={() => handleViewPaymentRecordsClick(record.id!)}
                                disabled={record.amount === record.amountPending}
                            >
                                {descriptions.ACTIONS.PAYMENT_RECORDS}
                            </Button>
                        ),
                    },
                ];

                return <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Dropdown menu={{ items }} placement="bottomRight" arrow >
                        <Button
                            size="small"
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <DownOutlined rev={undefined} />
                        </Button>
                    </Dropdown>
                </div>
            },
        },
    ]

    const handleActionButtonClick = () => {
        dispatch(getPurchaseRecordsApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    const renderContent = () => (
        <PageCard size="small" title={descriptions.TITLE}>
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.purchaseRecords ?? []}
                tableColumns={tblColumns}
                showLoader={reduxProps.isLoadingData}
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                showActionButton
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs(), dayjs()]}
                actionButtonOnClick={handleActionButtonClick}
                summaryColumns={summaryColumns}
            />
        </PageCard >
    )

    return (
        <>
            {renderContent()}
            {
                selectedPurchase &&
                <PurchaseDetailModal
                    purchase={selectedPurchase}
                    open={!!selectedPurchase}
                    onAccept={() => { setSelectedPurchase(undefined) }}
                    onCancel={() => { setSelectedPurchase(undefined) }}
                />
            }
            <PurchasePaymentRecords
                purchasePayments={reduxProps.purchasePayments || []}
                open={!!reduxProps.purchasePayments}
                onAccept={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
                onCancel={() => { dispatch(cleanPurchasePaymentRecordsApi()) }}
            />
            <Loader
                isVisible={reduxProps.isGettingPurchase || reduxProps.isGettingPurchasePayments}
            />
        </>
    )
}
