import { parseDataKey } from "src/common/util";
import { CertifySaleSuccessAction, CreateSaleSuccessAction, ErrorAction, GetSaleByIdSuccessAction, GetSaleRecordsSuccessAction } from "./action";
import { CERTIFY_SALE_API, CERTIFY_SALE_API_ERROR, CERTIFY_SALE_API_SUCCESS, CLEAR_CURRENT_SALE, CREATE_SALE_API, CREATE_SALE_API_ERROR, CREATE_SALE_API_SUCCESS, GET_SALE_BY_ID_API, GET_SALE_BY_ID_API_ERROR, GET_SALE_BY_ID_API_SUCCESS, GET_SALE_RECORDS_API, GET_SALE_RECORDS_API_ERROR, GET_SALE_RECORDS_API_SUCCESS } from "./actionTypes";
import { SaleError, SaleErrorOrigin } from "./types";
import { Sale } from "src/common/models/sale";

export interface SaleState {
    error?: SaleError
    createSaleSuccess: boolean
    upsertedSale?: Sale
    sales?: Sale[]
    currentSale?: Sale
    certifySaleSuccess: boolean
}

const initialState: SaleState = {
    createSaleSuccess: false,
    certifySaleSuccess: false,
}

const saleReducer = (state: SaleState = initialState, action: any): SaleState => {
    switch (action.type) {
        case CREATE_SALE_API: {
            return {
                ...state,
                error: undefined,
                createSaleSuccess: false,
            }
        }
        case CREATE_SALE_API_SUCCESS: {
            const successAction = action as CreateSaleSuccessAction;

            return {
                ...state,
                upsertedSale: successAction.sale,
                createSaleSuccess: true,
            }
        }
        case CREATE_SALE_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.CREATE_SALE,
                    detail: errorAction.error
                }
            }
        }
        case GET_SALE_RECORDS_API: {
            return {
                ...state,
                error: undefined,
            }
        }
        case GET_SALE_RECORDS_API_SUCCESS: {
            const successAction = action as GetSaleRecordsSuccessAction;

            return {
                ...state,
                sales: parseDataKey(successAction.sales),
            }
        }
        case GET_SALE_RECORDS_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.GET_SALE_RECORDS,
                    detail: errorAction.error,
                }
            }
        }
        case GET_SALE_BY_ID_API: {
            return {
                ...state,
                error: undefined,
                currentSale: undefined,
            }
        }
        case GET_SALE_BY_ID_API_SUCCESS: {
            const successAction = action as GetSaleByIdSuccessAction;

            return {
                ...state,
                currentSale: successAction.sale,
            }
        }
        case GET_SALE_BY_ID_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.GET_SALE_BY_ID,
                    detail: errorAction.error,
                }
            }
        }
        case CLEAR_CURRENT_SALE: {
            return {
                ...state,
                currentSale: undefined,
            }
        }
        case CERTIFY_SALE_API: {
            return {
                ...state,
                error: undefined,
                certifySaleSuccess: false,
            }
        }
        case CERTIFY_SALE_API_SUCCESS: {
            const successAction = action as CertifySaleSuccessAction;

            return {
                ...state,
                upsertedSale: successAction.sale,
                certifySaleSuccess: true,
            }
        }
        case CERTIFY_SALE_API_ERROR: {
            const errorAction = action as ErrorAction;

            return {
                ...state,
                error: {
                    type: SaleErrorOrigin.CERTIFY_SALE,
                    detail: errorAction.error
                }
            }
        }
    }
    return state;
};

export default saleReducer;