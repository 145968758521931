import { Action } from 'redux'
import * as Actions from './actionTypes'
import { Sale } from 'src/common/models/sale'

export interface CreateSaleAction extends Action {
    sale: Sale
}
export interface CreateSaleSuccessAction extends Action {
    sale: Sale
}
export interface ErrorAction extends Action {
    error?
}
export const createSaleApi = (sale: Sale): CreateSaleAction => ({
    type: Actions.CREATE_SALE_API,
    sale,
})
export const createSaleSuccess = (sale: Sale): CreateSaleSuccessAction => ({
    type: Actions.CREATE_SALE_API_SUCCESS,
    sale,
})
export const createSaleError = (error?): ErrorAction => ({
    type: Actions.CREATE_SALE_API_ERROR,
    error,
})

export interface GetSaleRecordsAction extends Action {
    dateFrom: string
    dateTo: string
}
export interface GetSaleRecordsSuccessAction extends Action {
    sales: Sale[]
}
export const getSaleRecordsApi = (dateFrom: string, dateTo: string): GetSaleRecordsAction => ({
    type: Actions.GET_SALE_RECORDS_API,
    dateFrom,
    dateTo,
})
export const getSaleRecordsSuccess = (sales: Sale[]): GetSaleRecordsSuccessAction => ({
    type: Actions.GET_SALE_RECORDS_API_SUCCESS,
    sales,
})
export const getSaleRecordsError = (error?): ErrorAction => ({
    type: Actions.GET_SALE_RECORDS_API_ERROR,
    error,
})

export interface GetSaleByIdAction extends Action {
    saleId: number
}
export interface GetSaleByIdSuccessAction extends Action {
    sale: Sale
}
export const getSaleByIdApi = (saleId: number): GetSaleByIdAction => ({
    type: Actions.GET_SALE_BY_ID_API,
    saleId,
})
export const getSaleByIdSuccess = (sale: Sale): GetSaleByIdSuccessAction => ({
    type: Actions.GET_SALE_BY_ID_API_SUCCESS,
    sale,
})
export const getSaleByIdError = (error?): ErrorAction => ({
    type: Actions.GET_SALE_BY_ID_API_ERROR,
    error,
})

export const clearCurrentSale = (): Action => ({
    type: Actions.CLEAR_CURRENT_SALE,
})

export interface CertifySaleAction extends Action {
    sale: Sale
}
export interface CertifySaleSuccessAction extends Action {
    sale: Sale
}
export const certifySaleApi = (sale: Sale): CertifySaleAction => ({
    type: Actions.CERTIFY_SALE_API,
    sale,
})
export const certifySaleSuccess = (sale: Sale): CertifySaleSuccessAction => ({
    type: Actions.CERTIFY_SALE_API_SUCCESS,
    sale,
})
export const certifySaleError = (error?): ErrorAction => ({
    type: Actions.CERTIFY_SALE_API_ERROR,
    error,
})