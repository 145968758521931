import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'
import { GetPurchaseRecordsFilters } from 'src/common/models/purchase'
import { GetPurchasePaymentRecordsFilters } from 'src/common/models/purchasePayment'

function* createPurchaseApi(action: Actions.CreatePurchaseAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.PURCHASE.CREATE, action.purchase)
        yield put(Actions.createPurchaseSuccess(response.data))
    } catch (err) {
        yield put(Actions.createPurchaseError(err));
    }
}

const buildQueryString = (filters?: GetPurchaseRecordsFilters) => {
    const filtersList: string[] = []
    if (filters?.dateFrom && filters.dateTo) {
        filtersList.push(`dateFrom=${filters.dateFrom}&dateTo=${filters.dateTo}`)
    }
    if (filters?.paymentStatusId) {
        filtersList.push(`paymentStatus=${filters.paymentStatusId}`)
    }

    return filtersList.length > 0
        ? `?${filtersList.join('&')}`
        : ``
}

function* getPurchaseRecordsApi(action: Actions.GetPurchaseRecordsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PURCHASE.GET_RECORDS(buildQueryString(action.filters)))
        yield put(Actions.getPurchaseRecordsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPurchaseRecordsError(err));
    }
}

function* getPurchaseByIdApi(action: Actions.GetPurchaseByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PURCHASE.GET_BY_ID(action.purchaseId))
        yield put(Actions.getPurchaseByIdSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPurchaseByIdError(err));
    }
}

function* createPurchasePaymentApi(action: Actions.CreatePurchasePaymentAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.PURCHASE_PAYMENT.CREATE, action.paymentData)
        yield put(Actions.createPurchasePaymentSuccess(response.data))
    } catch (err) {
        yield put(Actions.createPurchasePaymentError(err));
    }
}

const buildPurchasePaymentsQueryString = (filters?: GetPurchasePaymentRecordsFilters) => {
    const filtersList: string[] = []
    if (filters?.dateFrom && filters.dateTo) {
        filtersList.push(`dateFrom=${filters.dateFrom}&dateTo=${filters.dateTo}`)
    }
    if (filters?.purchaseId) {
        filtersList.push(`purchaseId=${filters.purchaseId}`)
    }
    if (filters?.supplierId) {
        filtersList.push(`supplierId=${filters.supplierId}`)
    }

    return filtersList.length > 0
        ? `?${filtersList.join('&')}`
        : ``
}

function* getPurchasePaymentsApi(action: Actions.GetPurchasePaymentRecordsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.PURCHASE_PAYMENT.GET_RECORDS(buildPurchasePaymentsQueryString(action.filters)))
        yield put(Actions.getPurchasePaymentRecordsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getPurchasePaymentRecordsError(err));
    }
}

export default function* productSagas() {
    yield takeLatest(ActionTypes.CREATE_PURCHASE_API, createPurchaseApi)
    yield takeLatest(ActionTypes.GET_PURCHASE_RECORDS_API, getPurchaseRecordsApi)
    yield takeLatest(ActionTypes.GET_PURCHASE_BY_ID_API, getPurchaseByIdApi)
    yield takeLatest(ActionTypes.CREATE_PURCHASE_PAYMENT_API, createPurchasePaymentApi)
    yield takeLatest(ActionTypes.GET_PURCHASE_PAYMENTS_API, getPurchasePaymentsApi)
}