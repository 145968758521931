import { SessionData, SessionDataField } from './types'

export const getSessionData = (): SessionData => ({
    accessToken: localStorage.getItem(SessionDataField.ACCESS_TOKEN) || '',
    user: JSON.parse(localStorage.getItem(SessionDataField.USER) as string),
    saleDetail: JSON.parse(localStorage.getItem(SessionDataField.CURRENT_SALE) as string),
    purchaseDetail: JSON.parse(localStorage.getItem(SessionDataField.CURRENT_PURCHASE) as string),
})

export const storeSessionData = (sessionData: SessionData) => {
    if (sessionData.accessToken !== undefined) {
        localStorage.setItem(SessionDataField.ACCESS_TOKEN, sessionData.accessToken)
    }
    if (sessionData.user !== undefined) {
        localStorage.setItem(SessionDataField.USER, JSON.stringify(sessionData.user))
    }
    if (sessionData.saleDetail !== undefined) {
        localStorage.setItem(SessionDataField.CURRENT_SALE, JSON.stringify(sessionData.saleDetail))
    }
    if (sessionData.purchaseDetail !== undefined) {
        localStorage.setItem(SessionDataField.CURRENT_PURCHASE, JSON.stringify(sessionData.purchaseDetail))
    }
}

export const removeSessionData = (sessionDataField: SessionDataField) => {
    if (sessionDataField === SessionDataField.CURRENT_SALE) {
        localStorage.removeItem(SessionDataField.CURRENT_SALE)
    }
    if (sessionDataField === SessionDataField.CURRENT_PURCHASE) {
        localStorage.removeItem(SessionDataField.CURRENT_PURCHASE)
    }
}

export const clearSessionData = () => {
    localStorage.clear()
}