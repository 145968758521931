import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { UnitsSoldReport } from "./types"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_UNITS_SOLD_REPORT_API } from "../../state/actionTypes"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { TABLE_COLUMNS } from "./config"
import { useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import moment from "moment"
import dayjs from 'dayjs'
import { SystemConstants } from "src/common/constants"
import { getUnitsSoldReporttApi } from "../../state/action"

interface ReduxProps {
    isLoadingData: boolean
    report?: UnitsSoldReport[]
}

export const UnitsSold = () => {
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_UNITS_SOLD_REPORT_API]),
        report: state.report.unitsSoldReport,
    }))

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().startOf('month').format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const handleDateRangesChange = async (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const getReportData = () => {
        dispatch(getUnitsSoldReporttApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    const handleActionButtonClick = () => {
        getReportData()
    }

    const renderContent = () => (
        <PageCard size="small" title={"Products Kardex"}>
            <SearchableTable
                showSearch
                searchLabel={"search"}
                items={reduxProps.report ?? []}
                tableColumns={TABLE_COLUMNS}
                showLoader={reduxProps.isLoadingData}
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs().startOf('month'), dayjs()]}
                showActionButton
                actionButtonLabel={"Generar"}
                actionButtonOnClick={handleActionButtonClick}
            />
        </PageCard >
    )

    return (
        renderContent()
    )
}
